<template>
  <div class="festival">
    <Header class="hide" />
    <Headers class="dispy" />
    <div class="bar">
      <BannerImage rulename="query_banner" />
    </div>
    <div class="w">
      <Breadcrumb :crumblist="crumbdata" class="hide" />
      <div class="tilp">
        <span class="jiaotong">节庆介绍</span>
      </div>
      <div class="dec">
        <p v-html="result.cont"></p>
      </div>
      <div class="biaoo">
        <span>节庆活动</span>
      </div>
      <div class="info">
        <el-row>
          <el-col :md="6" v-for="item in info" :key="item">
            <router-link :to="{ path: '/festidata', query: { id: item.id } }">
              <div class="xiao">
                <img :src="'/jeecg-boot/' + item.theLocal" alt="" />
                <div class="ren-text">
                  <p>{{ item.theTitle }}</p>
                </div>
                <div class="ren-xq">
                  <p>
                    {{ item.theInfo }}
                  </p>
                </div>
              </div>
            </router-link>
          </el-col>
        </el-row>
      </div>
      <Pagi
        :total="pagi.total"
        :current="pagi.current"
        :sizes="pagi.size"
        @pagicurrent="togglePagi"
      />
    </div>
    <Footer />
  </div>
</template>
<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import Pagi from "@/components/Pagi";
import {
  loadSingleConfig,
  loadPictureConfig,
  loadVideoConfig,
} from "@/api/api";
import { getAction } from "@/api/manage";
import BannerImage from "@/components/main/BannerImage";
export default {
  created() {
    this.loadInfo(1);
    this.loadData();
  },
  components: {
    Header,
    Headers,
    Footer,
    Pagi,
    Breadcrumb,
    BannerImage,
  },
  data() {
    return {
      result: "",
      info: [],
      pagi: {
        total: 0,
        current: 1,
        size: 12,
      },
      crumbdata: [
        { name: "信息查询", url: "" },
        { name: "旅游节庆", url: "/festival" },
      ],
    };
  },
  methods: {
    loadData() {
      loadSingleConfig({
        rule: "festival_tel",
      }).then((res) => {
        if (res.success) {
          this.result = res.result;
        }
      });
    },
    loadInfo(value) {
      getAction("/festivalact/festivalActivities/list", {
        pageNo: value,
      }).then((res) => {
        if (res.success) {
          this.info = res.result.records;
          this.pagi.total = res.result.total;
          this.pagi.current = res.result.current;
          this.pagi.size = res.result.size;
        }
      });
    },
    togglePagi(value) {
      this.loadInfo(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.festival {
  .bars {
    width: 100%;
  }
  background: url(/img/white-bg.9771c1fc.png) no-repeat fixed top left;
  .tilp {
    background: url("~@/assets/image/main/29.png");
    background-repeat: no-repeat;
    background-position: 49.5% 90%; /*这个是按从左往右，从上往下的百分比位置进行调整*/
    text-align: center;
    .jiaotong {
      margin-top: 37px;
      margin-bottom: 56px;
      font-size: 22px;
      color: #6EB400;
    }
  }
  .dec {
    margin-bottom: 118px;
    p {
      width: 1120px;
      font-size: 14px;
      line-height: 26px;
      color: #333333;
      margin-left: 27px;
      margin-top: 35px;
    }
  }
  .biaoo {
    height: 52px;
    background-color: #6EB400;
    margin-bottom: 28px;
    padding-top: 14px;
    text-align: center;
    margin-left: 17px;
    span {
      font-size: 24px;
      line-height: 34px;
      color: #ffffff;
      text-align: center;
    }
  }
  .whole {
    width: 556px;
    height: 492px;
    background-color: #ffffff;
    border: solid 1px #dbdbdb;
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 22px;
    img {
      width: 532px;
      height: 350px;
      margin-left: 12px;
      margin-top: 12px;
    }
    .title {
      width: 144px;
      height: 18px;
      font-size: 18px;
      line-height: 29px;
      color: #333333;
      margin-left: 17px;
    }
    .xia {
      float: right;
      margin-right: 18px;
      margin-top: -11px;
    }
    .jq {
      margin-left: 17px;
      margin-top: 9px;
      margin-bottom: 18px;
      .jq-text {
        font-size: 18px;
        line-height: 29px;
        letter-spacing: 0px;
        color: #333333;
      }
    }
    .xq {
      margin-left: 17px;
      margin-bottom: 18px;
      p {
        font-size: 14px;
        line-height: 26px;
        color: #666666;
      }
    }
  }
  .xiao {
    width: 266px;
    height: 316px;
    background-color: #ffffff;
    border: solid 1px #dbdbdb;
    margin-left: 15px;
    margin-top: 20px;
    img {
      width: 242px;
      height: 160px;
      margin-top: 12px;
      margin-left: 12px;
    }
    .ren-text {
      width: 240px;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0px;
      
      color: #333333;
      p {
        margin-left: 12px;
        margin-top: 9px;
        overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      }
    }
    .ren-xq {
      width: 242px;

      font-size: 14px;
      line-height: 26px;
      color: #666666;
      margin-left: 12px;
      margin-right: 12px;
      margin-top: 9px;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
  .xiao:hover {
    transition-delay: 0.2s;
    box-shadow: 0px 2px 5px 0px rgba(42, 74, 128, 0.13);
  }
  .whole:hover {
    transition-delay: 0.2s;
    box-shadow: 0px 2px 5px 0px rgba(42, 74, 128, 0.13);
  }
  .el-row {
    padding-bottom: 110px;
  }
  a :hover {
    color: #6EB400;
  }
}
</style>
